<template>
  <div class="page-container">
    <el-tabs v-model="tabShow">
      <el-tab-pane label="题库管理" name="bank" class="base-pane bank-pane">
        <!--筛选-->
        <div class="filter-container clearFix">
          <list-search-filter :search-filter="bank.lists.searchFilter"
                              @clickSearchFilterBtn="query=>BankMethods().clickSearchFilterBtn(query)">
            <!--  操作  -->
            <div slot="right-container">
              <el-button class="el-button" type="primary" size="small"
                         @click="BankMethods().clickAddEntityBtn()">新增题库
              </el-button>
            </div>
          </list-search-filter>
        </div>
        <!--列表-->
        <div class="table-container">
          <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="bank.lists.list"
                    v-loading="bank.lists.loading"
                    element-loading-text="加载中" fit
                    style="width: 100%;" @sort-change="sort=>BankMethods().sortChange(sort)">
            <el-table-column label="题库名称">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属实验">
              <template slot-scope="scope">
                <span>{{ bank.lists.searchFilter.filter[0].dataObject[scope.row.experimentId] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="题库类型" width="100">
              <template slot-scope="scope">
                <span>{{ EnumsModel.questionBankType[scope.row.type] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="题目数量" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.questionNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="scope">
                <span>{{ date_format(scope.row.createTime, "yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="200"
                             class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <template v-if="scope.row.type!=='SYSTEM'">
                  <el-button type="text" size="mini" round
                             @click="BankMethods().clickEditBtn(scope.row,scope.$index)">修改
                  </el-button>
                  <el-button type="text" size="mini" round
                             @click="BankMethods().clickEditQuestionBtn(scope.row,scope.$index)">编辑试题
                  </el-button>
                  <el-button type="danger" size="small" round
                             @click="BankMethods().clickEnterSingleModelBtn(scope.row,scope.$index)">进入课堂单题考核模式
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination background @current-change="(number)=>BankMethods().pageChange(number)"
                         :current-page.sync="bank.lists.pages.number" :page-size.sync="bank.lists.pages.size"
                         layout="total,prev, pager, next,sizes,jumper" :total="bank.lists.pages.totalElements"
                         @size-change="(size)=>BankMethods().pageLimitChange(size)"
                         :page-count="bank.lists.pages.totalPages">
          </el-pagination>
        </div>
        <!--详情弹窗-题库详情-->
        <el-dialog
            :close-on-click-modal="false"
            :title="bank.entityInfo.title"
            :visible.sync="bank.entityInfo.dialog"
            width="700px"
            center
            v-el-drag-dialog>
          <div class="dialog-container">
            <el-form label-width="120px" ref="bank_entityInfoForm" :model="bank.entityInfo.edit"
                     :rules="bank.entityInfo.formRules">
              <el-form-item label="题库名称：" prop="name">
                <el-input style="width: 500px" v-model.trim="bank.entityInfo.edit.name" placeholder="请输入题库名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="题库类型：" prop="type">
                <el-select v-model="bank.entityInfo.edit.type">
                  <el-option v-for="item in bank.entityInfo.addQuestionBankTypeSelectData"
                             :label="item.label" :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属实验：" prop="experimentId">
                <el-select v-model="bank.entityInfo.edit.experimentId" placeholder="请选择实验课程" style="width: 400px"
                           :disabled="bank.entityInfo.type==='edit'">
                  <el-option v-for="(item,index) in bank.lists.searchFilter.filter[0].data" :value="item.value"
                             :key="index"
                             :label="item.label" v-if="item.value!==''"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="bank.entityInfo.dialog=false">取 消</el-button>
        <el-button type="primary" v-if="bank.entityInfo.type==='add'" :loading="bank.saveOrEditDoing"
                   @click="BankMethods().clickAddBtn()">新 增</el-button>
         <el-button type="primary" v-if="bank.entityInfo.type==='edit'" :loading="bank.saveOrEditDoing"
                    @click="BankMethods().clickSaveBtn()">修 改</el-button>
      </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="考卷管理" name="paper" class="base-pane paper-pane">
        <!--筛选-->
        <div class="filter-container clearFix">
          <list-search-filter :search-filter="paper.lists.searchFilter"
                              @clickSearchFilterBtn="query=>PaperMethods().clickSearchFilterBtn(query)">
            <!--  操作  -->
            <div slot="right-container">
              <el-button class="el-button" type="primary" size="small"
                         @click="PaperMethods().clickAddPaperBtn()">新增考卷
              </el-button>
            </div>
          </list-search-filter>
        </div>
        <!--列表-->
        <div class="table-container">
          <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="paper.lists.list"
                    v-loading="paper.lists.loading"
                    element-loading-text="加载中" fit
                    style="width: 100%;" @sort-change="sort=>PaperMethods().sortChange(sort)">
            <el-table-column label="编号">
              <template slot-scope="scope">
                <span>{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="考卷名称">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属实验">
              <template slot-scope="scope">
                <span>{{ paper.lists.searchFilter.filter[0].dataObject[scope.row.experimentId] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="题目数量">
              <template slot-scope="scope">
                <span>{{ scope.row.questionNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="总分">
              <template slot-scope="scope">
                <span>{{ scope.row.totalScore }}</span>
              </template>
            </el-table-column>
            <el-table-column label="及格分数">
              <template slot-scope="scope">
                <span>{{ scope.row.passScore }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <span>{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="scope">
                <span>{{ date_format(scope.row.createTime, "yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="200"
                             class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <template v-if="scope.row.type!=='SYSTEM'">
                  <el-button type="text" size="mini" round
                             @click="PaperMethods().clickEditBtn(scope.row,scope.$index)">修改
                  </el-button>
                  <el-button type="text" size="mini" round
                             @click="PaperMethods().clickViewBtn(scope.row,scope.$index)">预览
                  </el-button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination background @current-change="(number)=>PaperMethods().pageChange(number)"
                         :current-page.sync="paper.lists.pages.number" :page-size.sync="paper.lists.pages.size"
                         layout="total,prev, pager, next,sizes,jumper" :total="paper.lists.pages.totalElements"
                         @size-change="(size)=>PaperMethods().pageLimitChange(size)"
                         :page-count="paper.lists.pages.totalPages">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="考核安排" name="exam" class="base-pane exam-pane">
        <!--筛选-->
        <div class="filter-container clearFix">
          <list-search-filter :search-filter="exam.lists.searchFilter"
                              @clickSearchFilterBtn="query=>ExamMethods().clickSearchFilterBtn(query)">
            <!--  操作  -->
            <div slot="right-container">
              <el-button class="el-button" type="primary" size="small"
                         @click="ExamMethods().clickAddEntityBtn()">新增考核
              </el-button>
            </div>
          </list-search-filter>
        </div>
        <!--列表-->
        <div class="table-container">
          <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="exam.lists.list"
                    v-loading="exam.lists.loading"
                    element-loading-text="加载中" fit
                    style="width: 100%;" @sort-change="sort=>ExamMethods().sortChange(sort)">
            <el-table-column label="考核类型" width="50">
              <template slot-scope="scope">
                <span>{{ EnumsModel.examType[scope.row.type] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="考核名称">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="考卷名称">
              <template slot-scope="scope">
                <span v-if="scope.row.type==='multiple'">{{ scope.row.paperName }}</span>
                <span v-if="scope.row.type==='single'">/</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="70">
              <template slot-scope="scope">
                <span>{{ EnumsModel.examStatus[scope.row.status] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属实验">
              <template slot-scope="scope">
                <span>{{ exam.lists.searchFilter.filter[0].dataObject[scope.row.experimentId] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="教学班名称">
              <template slot-scope="scope">
                <span>{{ scope.row.clazzName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="应答学生" width="50">
              <template slot-scope="scope">
                <span>{{ scope.row.studentNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始作答学生" width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.results.length }}</span>
              </template>
            </el-table-column>
            <el-table-column label="考核时间范围" width="168">
              <template slot-scope="scope">
                <span>{{
                    date_format(scope.row.startTime, "yyyy-MM-dd HH:mm:ss")
                  }}{{ date_format(scope.row.endTime, "yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="110">
              <template slot-scope="scope">
                <span>{{ date_format(scope.row.createTime, "yyyy-MM-dd HH:mm:ss") }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="300"
                             class-name="small-padding fixed-width">
              <template slot-scope="scope">
                <template v-if="scope.row.type!=='SYSTEM'">
                  <el-button type="text" size="mini" round
                             @click="ExamMethods().clickEditBtn(scope.row,scope.$index)">修改
                  </el-button>
                  <el-dropdown size="medium" center style="margin-left: 15px"
                               @command="c=>ExamMethods().clickDropList(c,scope.row)">
                    <el-button type="success">
                      更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="paperView">考卷预览</el-dropdown-item>
                      <el-dropdown-item command="qrcode" style="color: red;font-weight: bold">考核二维码
                      </el-dropdown-item>
                      <el-dropdown-item command="list" style="color: red;font-weight: bold">答卷状态和成绩列表
                      </el-dropdown-item>
                      <el-dropdown-item v-if="scope.row.type==='single'" command="single"
                                        style="color: red;font-weight: bold">进入单题答卷详情
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--列表分页-->
        <div class="pagination-container">
          <el-pagination background @current-change="(number)=>ExamMethods().pageChange(number)"
                         :current-page.sync="exam.lists.pages.number" :page-size.sync="exam.lists.pages.size"
                         layout="total,prev, pager, next,sizes,jumper" :total="exam.lists.pages.totalElements"
                         @size-change="(size)=>ExamMethods().pageLimitChange(size)"
                         :page-count="exam.lists.pages.totalPages">
          </el-pagination>
        </div>
        <!--详情弹窗-考核详情-->
        <el-dialog
            :close-on-click-modal="false"
            :title="exam.entityInfo.title"
            :visible.sync="exam.entityInfo.dialog"
            width="700px"
            center
            v-el-drag-dialog>
          <div class="dialog-container">
            <el-form label-width="120px" ref="exam_entityInfoForm" :model="exam.entityInfo.edit"
                     :rules="exam.entityInfo.formRules">
              <el-form-item label="考核名称：" prop="name">
                <el-input style="width: 500px" v-model.trim="exam.entityInfo.edit.name" placeholder="请输入考核名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属实验：" prop="experimentId">
                <el-select v-model="exam.entityInfo.edit.experimentId" placeholder="请选择实验课程" style="width: 400px"
                           :disabled="exam.entityInfo.type==='edit'"
                           @change="v=>ExamMethods().experimentIdChange(v)"
                >
                  <el-option v-for="(item,index) in exam.lists.searchFilter.filter[0].data" :value="item.value"
                             :key="index"
                             :label="item.label" v-if="item.value!==''"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择考卷：" prop="paperId" v-if="exam.entityInfo.edit.experimentId">
                <el-select v-model="exam.entityInfo.edit.paperId" placeholder="请选择考卷" style="width: 400px"
                           :disabled="exam.entityInfo.type==='edit'"
                >
                  <el-option v-for="(item,index) in exam.entityInfo.filter.paperOptions" :value="item.value"
                             :key="index"
                             :label="item.label" v-if="item.value!==''"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学班级:" prop="clazzId">
                <el-select v-model="exam.entityInfo.edit.clazzId" placeholder="请选择班级"
                           :disabled="exam.entityInfo.type==='edit'"
                           style="width: 400px">
                  <el-option v-for="(item,index) in exam.entityInfo.filter.clazzOptions" :value="item.value"
                             :key="index"
                             :label="item.label" v-if="item.value!==''"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="考核时间" prop="date">
                <el-date-picker
                    v-model="exam.entityInfo.edit.date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00','23:59:59']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="exam.entityInfo.datePickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="备注信息：" prop="remark">
                <el-input style="width: 500px" v-model="exam.entityInfo.edit.remark" placeholder="请输入考核备注信息"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
        <el-button type="default" @click="exam.entityInfo.dialog=false">取 消</el-button>
        <el-button type="primary" v-if="exam.entityInfo.type==='add'" :loading="exam.saveOrEditDoing"
                   @click="ExamMethods().clickAddBtn()">新 增</el-button>
         <el-button type="primary" v-if="exam.entityInfo.type==='edit'" :loading="exam.saveOrEditDoing"
                    @click="ExamMethods().clickSaveBtn()">修 改</el-button>
      </span>
        </el-dialog>
        <!--详情弹窗-答卷和成绩列表-->
        <el-dialog
            :close-on-click-modal="false"
            :title="exam.listD.title"
            :visible.sync="exam.listD.dialog"
            width="1000px"
            center
            v-el-drag-dialog>
          <div class="dialog-container">
            <div style="margin-bottom: 10px" class="clearFix">
              <el-button :disabled="exam.listD.allList.length===0" type="success" size="small" class="fr"
                         @click="ExamMethods().exportList()">导出答卷状态和成绩列表
              </el-button>
            </div>
            <tab-table-list :tab-table-list="exam.listD.statusList" tab="all"></tab-table-list>

          </div>
          <div slot="footer" class="dialog-footer">
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {msg_confirm, msg_err, msg_success} from "@/utils/ele_component";
import {date_format, find_obj_from_arr_by_id, objectToLVArr, validateMaxLength} from "@/utils/common";
import {EnumsModel} from "@/model/EnumsModel";
import {CommonModel} from "@/model/CommonModel";
import ListSearchFilter from "@/components/list/listSearchFilter.vue";
import elDragDialog from "@/directive/el-drag-dialog";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {QuestionBankModel} from "@/model/exp/QuestionBankModel";
import {QuestionPaperModel} from "@/model/exp/QuestionPaperModel";
import {QuestionExamModel} from "@/model/exp/QuestionExamModel";
import {ToolsModel} from "@/model/ToolsModel";
import {EXAM_H5_URL} from "@/model/ConfigModel";
import tabTableList from '@/views/components/tabTableList'
import {excel_export_from_json} from "@/utils/excel";
import {QuestionModel} from "@/model/exp/QuestionModel";

window.$vue = undefined;
// 学生列表表格定义
const studentListDefine = [
  {
    label: '学生账号',
    id: 'studentAccount',
    width: '150'
  },
  {
    label: '学生姓名',
    id: 'studentName',
    width: '150'
  },
  {
    label: '开始答卷时间',
    id: 'resultStartTime',
    width: '105',
    f: (v) => {
      if (v === null) {
        return "/"
      }
      return date_format(v, "yyyy-MM-dd HH:mm:ss")
    }
  },
  {
    label: '交卷时间',
    id: 'resultEndTime',
    width: '105',
    f: (v) => {
      if (v === null) {
        return "/"
      }
      return date_format(v, "yyyy-MM-dd HH:mm:ss")
    }
  },
  {
    label: '得分',
    id: 'resultScore',
    width: '69',
    f: (v) => {
      if (v === null) {
        return 0
      }
      return v
    }
  },
  {
    label: '操作',
    id: 'resultId',
    width: '100',
    html: true,
    f: (v) => {
      if (v !== null) {
        return `<a href='#' onclick="window.$vue.ExamMethods().clickViewResultBtn(${v})" style="text-decoration: none;color:#409eff">查看答卷</a>`
      }
    }
  }
]

export default {
  name: "teacherExam",
  components: {ListSearchFilter, tabTableList},
  // 指令
  directives: {
    elDragDialog
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      objectToLVArr,
      EnumsModel: EnumsModel,
      tabShow: "bank",
      // 题库管理相关
      bank: {
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 10
          },
          searchFilter: {
            search: [
              {
                type: 'input',
                label: '题库名称',
                key: 'name',
                value: ''
              },
            ],
            filter: [
              {
                type: 'select',
                label: '实验课程',
                key: 'experimentId',
                value: '',
                data: [],
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
              {
                type: 'select',
                label: '题库类型',
                key: 'type',
                value: '',
                data: objectToLVArr(EnumsModel.questionBankType, true),
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
            ]
          }
        },
        // 实体详情
        entityInfo: {
          title: "新增班级",
          type: "add",
          dialog: false,
          addQuestionBankTypeSelectData: [],
          filter: [],
          edit: {
            // 选择的行政班id列表
            administrationClazzIdsShow: []
          },
          firstCheck: false,
          // 输入检测
          formRules: {
            // 'administrationClazzIdsShow': {required: true, message: '请选择行政班级', trigger: 'change'},
            'name': {
              required: true,
              validator: (r, v, c) => validateMaxLength(r, v, c, 30, "题库名称"),
              trigger: 'blur'
            },
          },
        },
        // 保存或新增加载
        saveOrEditDoing: false,
      },
      // 考卷管理相关
      paper: {
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 10
          },
          searchFilter: {
            search: [
              {
                type: 'input',
                label: '考卷名称',
                key: 'name',
                value: ''
              },
            ],
            filter: [
              {
                type: 'select',
                label: '实验课程',
                key: 'experimentId',
                value: '',
                data: [],
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
            ]
          }
        },
        // 实体详情
        entityInfo: {
          title: "新增班级",
          type: "add",
          dialog: false,
          addQuestionBankTypeSelectData: [],
          filter: [],
          edit: {
            // 选择的行政班id列表
            administrationClazzIdsShow: []
          },
          firstCheck: false,
          // 输入检测
          formRules: {
            // 'administrationClazzIdsShow': {required: true, message: '请选择行政班级', trigger: 'change'},
            'name': {
              required: true,
              validator: (r, v, c) => validateMaxLength(r, v, c, 30, "题库名称"),
              trigger: 'blur'
            },
          },
        },
        // 保存或新增加载
        saveOrEditDoing: false,
      },
      // 考核管理相关
      exam: {
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 10
          },
          searchFilter: {
            search: [
              {
                type: 'input',
                label: '考核名称',
                key: 'name',
                value: ''
              },
            ],
            filter: [
              {
                type: 'select',
                label: '实验课程',
                key: 'experimentId',
                value: '',
                data: [],
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
              {
                type: 'select',
                label: '考核类型',
                key: 'type',
                value: '',
                data: objectToLVArr(EnumsModel.examType, true),
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
              {
                type: 'select',
                label: '考核状态',
                key: 'status',
                value: '',
                data: objectToLVArr(EnumsModel.examStatus, true),
                dataObject: {},
                dataOrigin: [],// 存储数据库返回的默认列表
                change: function (value) {
                }
              },
            ]
          }
        },
        // 实体详情
        entityInfo: {
          title: "考核详情",
          type: "add",
          dialog: false,
          addQuestionBankTypeSelectData: [],
          filter: {
            paperOptions: [],
            paperObject: {},
            paperOrigin: [],
            clazzOptions: [],
            clazzObject: {},
            clazzOrigin: [],
          },
          edit: {
            // 选择的行政班id列表
            administrationClazzIdsShow: []
          },
          firstCheck: false,
          // 输入检测
          formRules: {
            // 'administrationClazzIdsShow': {required: true, message: '请选择行政班级', trigger: 'change'},
            'name': {
              required: true,
              validator: (r, v, c) => validateMaxLength(r, v, c, 30, "考核名称"),
              trigger: 'blur'
            },
            'experimentId': {required: true, message: '请选择实验', trigger: 'change'},
            'paperId': {required: true, message: '请选择考卷', trigger: 'change'},
            'clazzId': {required: true, message: '请选择教学班级', trigger: 'change'},
            'date': {required: true, message: '请选择考核开放时间', trigger: 'change'},
          },
          // 实验授权时间禁止选择时间
          datePickerOptions: {
            shortcuts: [
              {
                text: '5分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 5 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '10分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 10 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '20分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 20 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '30分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 30 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '45分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 45 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '60分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 60 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '本日内',
                onClick(picker) {
                  let start = new Date();
                  const end = new Date();
                  start.setHours(0, 0, 0, 0);
                  end.setHours(23, 59, 59, 59);
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '本周内',
                onClick(picker) {
                  let date = new Date();
                  const end = new Date();
                  const dayOfWeek = date.getDay();
                  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
                  const start = new Date(date.setDate(diff));
                  // 将日期调整为星期一
                  start.setDate(start.getDate() - start.getDay() + 1);
                  start.setHours(0, 0, 0, 0);
                  picker.$emit('pick', [start, end]);
                }
              },
            ],
            disabledDate: (time) => {
              return time.getTime() < Date.now() - 8.64e7
            }
          },
        },
        // 列表弹窗
        listD: {
          title: "答卷状态和成绩列表",
          dialog: false,
          tabName: "status",
          statusList: [
            {
              label: '所有',
              name: 'all',
              list: [],
              listDefine: studentListDefine
            },
            {
              label: '未答卷',
              name: 'UnStart',
              list: [],
              listDefine: studentListDefine
            },
            {
              label: '未交卷',
              name: 'UnSubmited',
              list: [],
              listDefine: studentListDefine
            },
            {
              label: '已交卷',
              name: 'CalculatedScore',
              list: [],
              listDefine: studentListDefine
            }
          ],
          allList: [],
        },
        // 保存或新增加载
        saveOrEditDoing: false,
      },
    }
  },
  beforeCreate() {
    window.$vue = this;
  },
  async mounted() {
    // 初始化筛选-题库管理
    await this.BankMethods().initFilter()
    // 获取题库列表
    this.BankMethods().getList(1, this.bank.lists.pages.size, this.bank.lists.query)

    // 初始化筛选-考卷管理
    await this.PaperMethods().initFilter()
    // 获取考卷列表
    this.PaperMethods().getList(1, this.paper.lists.pages.size, this.paper.lists.query)

    // 初始化筛选-考核管理
    await this.ExamMethods().initFilter()
    // 获取考核列表
    this.ExamMethods().getList(1, this.exam.lists.pages.size, this.exam.lists.query)
  },
  methods: {
    date_format,
    // 题库相关方法集
    BankMethods() {
      let $this = this;
      return {
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.bank.lists.queryBase)
          $this.bank.lists.loading = true;
          query = Object.assign({
            schoolId: $this.userInfo.schoolId,
            departmentId: $this.userInfo.departmentid
          }, query)
          // 如果没有排序 默认班级名称排序
          if (!query.sort) {
            query.sort = "id,asc"
          }
          [$this.bank.lists.list, $this.bank.lists.pages] = await QuestionBankModel.getList(page, size, query)
          $this.bank.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.bank.lists.pages.size, $this.bank.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.bank.lists.pages.number, size, $this.bank.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.bank.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            $this.bank.$set($this.bank.lists.query, "sort", querySort)
            this.getList(1, $this.bank.lists.pages.size, $this.bank.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type, setNull) {
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.bank.lists.searchFilter.filter[0], "data", generateListFilter1[0])
            $this.$set($this.bank.lists.searchFilter.filter[0], "dataObject", generateListFilter1[1])
            $this.$set($this.bank.lists.searchFilter.filter[0], "dataOrigin", experimentList)
          }
          $this.$forceUpdate();
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.bank.lists.pages.size, query)
          $this.bank.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该班级吗？")) {
            if (await ClazzModel.remove([entity.clazzid])) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 列表-点击新增班级按钮
        clickAddEntityBtn() {
          $this.bank.entityInfo.type = "add"
          $this.bank.entityInfo.title = "新增题库"
          $this.bank.entityInfo.edit = {
            questionBankType: "PRIVATE"
          }
          // 删除题库类型中的系统题库，让普通用户不能设置
          let addQuestionBankTypeSelectData = objectToLVArr(EnumsModel.questionBankType, false)
          addQuestionBankTypeSelectData.splice(0, 1)
          $this.bank.entityInfo.addQuestionBankTypeSelectData = addQuestionBankTypeSelectData

          $this.bank.entityInfo.dialog = true;
          setTimeout(() => {
            $this.$refs["bank_entityInfoForm"].clearValidate()
          }, 300)
        },
        // 列表-点击编辑详情按钮
        clickEditBtn(entity, $index) {
          $this.bank.entityInfo.type = "edit"
          $this.bank.entityInfo.title = "修改题库"
          $this.bank.entityInfo.edit = JSON.parse(JSON.stringify(entity))
          $this.bank.entityInfo.$index = $index
          $this.bank.entityInfo.dialog = true;

          // 删除题库类型中的系统题库，让普通用户不能设置
          let addQuestionBankTypeSelectData = objectToLVArr(EnumsModel.questionBankType, false)
          addQuestionBankTypeSelectData.splice(0, 1)
          $this.bank.entityInfo.addQuestionBankTypeSelectData = addQuestionBankTypeSelectData

          setTimeout(() => {
            $this.$refs["bank_entityInfoForm"].clearValidate()
          }, 300)
        },
        // 列表-点击编辑试题按钮
        clickEditQuestionBtn(bank) {
          $this.$router.push("/teacher/questionList?bankId=" + bank.id)
        },
        // 列表-点击进入单题考核模式
        clickEnterSingleModelBtn(bank) {
          window.open("/teacher/examSingle?bankId=" + bank.id)
        },
        // 新增弹窗-点击新增按钮
        async clickAddBtn() {
          $this.$refs['bank_entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.bank.saveOrEditDoing = true
              // 构建参数
              $this.bank.entityInfo.edit.schoolId = $this.userInfo.schoolId // 所属学校
              $this.bank.entityInfo.edit.creatorId = $this.userInfo.userid // 创建人
              if (await QuestionBankModel.save($this.bank.entityInfo.edit).catch(() => {
                $this.bank.saveOrEditDoing = false
              })) {
                msg_success('新增成功')
                $this.BankMethods().getList(1, $this.bank.lists.pages.size, $this.bank.lists.query)
                $this.bank.entityInfo.dialog = false
              }
              $this.bank.entityInfo.firstCheck = false
              $this.bank.saveOrEditDoing = false
            }
          })
        },
        // 修改弹窗-点击修改按钮
        async clickSaveBtn() {
          $this.$refs['bank_entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.bank.saveOrEditDoing = true
              if (await QuestionBankModel.update($this.bank.entityInfo.edit).catch(() => {
                $this.bank.saveOrEditDoing = false
              })) {
                msg_success('修改成功')
                // 更新数据
                this.getList($this.bank.lists.pages.number, $this.bank.lists.pages.size, $this.bank.lists.query)
                $this.bank.entityInfo.dialog = false
                $this.bank.saveOrEditDoing = false
              }
            }
          });
        }
      }
    },
    // 考卷相关方法集
    PaperMethods() {
      let $this = this;
      return {
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.paper.lists.queryBase)
          $this.paper.lists.loading = true;
          query = Object.assign({}, query)
          // 如果没有排序 默认班级名称排序
          if (!query.sort) {
            query.sort = "id,asc"
          }
          [$this.paper.lists.list, $this.paper.lists.pages] = await QuestionPaperModel.getList(page, size, query)
          $this.paper.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.paper.lists.pages.size, $this.paper.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.paper.lists.pages.number, size, $this.paper.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.paper.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            $this.paper.$set($this.paper.lists.query, "sort", querySort)
            this.getList(1, $this.paper.lists.pages.size, $this.paper.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type, setNull) {
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.paper.lists.searchFilter.filter[0], "data", generateListFilter1[0])
            $this.$set($this.paper.lists.searchFilter.filter[0], "dataObject", generateListFilter1[1])
            $this.$set($this.paper.lists.searchFilter.filter[0], "dataOrigin", experimentList)
          }
          $this.$forceUpdate();
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.paper.lists.pages.size, query)
          $this.paper.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该班级吗？")) {
            if (await ClazzModel.remove([entity.clazzid])) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 列表-点击新增考卷按钮
        clickAddPaperBtn() {
          $this.$router.push("/teacher/paperEdit")
        },
        // 列表-点击编辑详情按钮
        clickEditBtn(paper, $index) {
          $this.$router.push("/teacher/paperEdit?paperId=" + paper.id)
        },
        // 列表-点击预览按钮
        clickViewBtn(paper, $index) {
          $this.$router.push({name: 'teacherExamPaper', query: {id: paper.id, asType: 'teacherView'}})
        }
      }
    },
    // 考核相关方法集
    ExamMethods() {
      let $this = this;
      return {
        // 获取列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.exam.lists.queryBase)
          $this.exam.lists.loading = true;
          query = Object.assign({}, query)
          // 如果没有排序 默认班级名称排序
          if (!query.sort) {
            query.sort = "id,asc"
          }
          [$this.exam.lists.list, $this.exam.lists.pages] = await QuestionExamModel.getList(page, size, query)
          $this.exam.lists.loading = false
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.exam.lists.pages.size, $this.exam.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.exam.lists.pages.number, size, $this.exam.lists.query)
        },
        // 排序被改变
        async sortChange(sort) {
          if (sort.column.sortable === 'custom') {
            let querySort = $this.exam.lists.query.sort
            querySort = CommonModel.elementTableSort(sort)
            $this.exam.$set($this.exam.lists.query, "sort", querySort)
            this.getList(1, $this.exam.lists.pages.size, $this.exam.lists.query)
          }
        },
        // 初始化筛选列表
        async initFilter(type, setNull) {
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
            $this.$set($this.exam.lists.searchFilter.filter[0], "data", generateListFilter1[0])
            $this.$set($this.exam.lists.searchFilter.filter[0], "dataObject", generateListFilter1[1])
            $this.$set($this.exam.lists.searchFilter.filter[0], "dataOrigin", experimentList)
          }
          if (!type || type === 1) {
            // 获取教学班级列表
            let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
            let dataArr = []
            clazzList.forEach(li => {
              dataArr.push({
                label: li.clazzName,
                value: li.clazzid
              })
            })
            $this.$set($this.exam.entityInfo.filter, "clazzOptions", dataArr)
            $this.$set($this.exam.entityInfo.filter, "clazzOrigin", clazzList)
          }
          $this.$forceUpdate();
        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          this.getList(1, $this.exam.lists.pages.size, query)
          $this.exam.lists.query = query
        },
        // 点击删除按钮
        async clickDeleteBtn(entity, index) {
          if (await msg_confirm("确认要删除该班级吗？")) {
            if (await ClazzModel.remove([entity.clazzid])) {
              msg_success("删除成功")
              $this.ListMethods().getList($this.lists.pages.number, $this.lists.pages.size, $this.lists.query)
            } else {
              msg_err("删除失败")
            }
          }
        },
        // 列表-点击新增班级按钮
        clickAddEntityBtn() {
          $this.exam.entityInfo.type = "add"
          $this.exam.entityInfo.title = "新增考核"
          $this.exam.entityInfo.edit = {
            name: "课堂考核 " + date_format(new Date(), "yyyy-MM-dd HH:mm"),
          }
          $this.exam.entityInfo.dialog = true;
          setTimeout(() => {
            $this.$refs["exam_entityInfoForm"].clearValidate()
          }, 300)
        },
        // 编辑弹窗-当选择的实验id变化
        async experimentIdChange(v) {
          // 获取该教师该实验的考卷
          let paperList = await QuestionPaperModel.getList(null, 0, {
            experimentId: v,
          }) // todo 排序？显示最新的
          let paperFilter = CommonModel.generateListFilterOptions('name', 'id', paperList, false)
          $this.$set($this.exam.entityInfo.filter, "paperOptions", paperFilter[0])
          $this.$set($this.exam.entityInfo.filter, "paperObject", paperFilter[1])
          $this.$set($this.exam.entityInfo.filter, "paperOrigin", paperFilter)
        },
        // 列表-点击编辑详情按钮
        clickEditBtn(entity, $index) {
          $this.exam.entityInfo.type = "edit"
          $this.exam.entityInfo.title = "修改考核信息"
          entity.date = [entity.startTime, entity.endTime]
          $this.exam.entityInfo.edit = JSON.parse(JSON.stringify(entity))
          $this.exam.entityInfo.$index = $index
          $this.exam.entityInfo.dialog = true;

          setTimeout(() => {
            $this.$refs["bank_entityInfoForm"].clearValidate()
          }, 300)
        },
        // 列表-点击编辑试题按钮
        clickEditQuestionBtn(bank) {
          $this.$router.push("/teacher/questionList?bankId=" + bank.id)
        },
        // 新增弹窗-点击新增按钮
        async clickAddBtn() {
          $this.$refs['exam_entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.exam.saveOrEditDoing = true
              // 构建参数
              let entity = JSON.parse(JSON.stringify($this.exam.entityInfo.edit))
              if (new Date(entity.date[1]).getTime() <= new Date(entity.date[0]).getTime()) {
                msg_err("开始时间不能小于结束时间！")
                return
              }
              entity.startTime = entity.date[0]
              entity.endTime = entity.date[1]

              entity.creatorId = $this.userInfo.userid // 创建人

              console.log(entity)
              if (await QuestionExamModel.save(entity).catch(() => {
                $this.exam.saveOrEditDoing = false
              })) {
                msg_success('新增成功')
                $this.ExamMethods().getList(1, $this.exam.lists.pages.size, $this.exam.lists.query)
                $this.exam.entityInfo.dialog = false
              }
              $this.exam.saveOrEditDoing = false
            }
          })
        },
        // 修改弹窗-点击修改按钮
        async clickSaveBtn() {
          $this.$refs['exam_entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.exam.saveOrEditDoing = true
              // 构建参数
              let entity = JSON.parse(JSON.stringify($this.exam.entityInfo.edit))
              if (new Date(entity.date[1]).getTime() <= new Date(entity.date[0]).getTime()) {
                msg_err("开始时间和结束时间不能选择同一天!")
                return
              }
              entity.startTime = entity.date[0]
              entity.endTime = entity.date[1]

              if (await QuestionExamModel.update(entity).catch(() => {
                $this.exam.saveOrEditDoing = false
              })) {
                msg_success('修改成功')
                // 更新数据
                this.getList($this.exam.lists.pages.number, $this.exam.lists.pages.size, $this.exam.lists.query)
                $this.exam.entityInfo.dialog = false
                $this.exam.saveOrEditDoing = false
              }
            }
          });
        },
        // 点击操作按钮
        async clickDropList(command, exam) {
          $this.exam.clickedExam = exam;
          switch (command) {
            case "paperView":
              $this.$router.push({name: 'teacherExamPaper', query: {id: exam.paperId, asType: 'teacherView'}})
              break;
            case "qrcode":
              // 二维码
              let url = EXAM_H5_URL
              let qrcodeUrl = await ToolsModel.getTextQrCode(url)
              msg_success("二维码生成成功，请让学生扫码开始答题考核。")
              window.open(qrcodeUrl)
              break;
            case "list":
              // 重置列表
              $this.$set($this.exam.listD, "statusList",
                  [
                    {
                      label: '所有',
                      name: 'all',
                      list: [],
                      listDefine: studentListDefine
                    },
                    {
                      label: '未答卷',
                      name: 'UnStart',
                      list: [],
                      listDefine: studentListDefine
                    },
                    {
                      label: '未交卷',
                      name: 'UnSubmited',
                      list: [],
                      listDefine: studentListDefine
                    },
                    {
                      label: '已交卷',
                      name: 'Submited',
                      list: [],
                      listDefine: studentListDefine
                    }
                  ]
              );
              this.getOneExamUserResultList({id: exam.id});
              $this.exam.listD.dialog = true;
              break;
            case "single":
              // 获取题库id
              let paper = await QuestionPaperModel.getOne(exam.paperId);
              let sectionInfos = JSON.parse(paper.sectionInfos);
              let questionId = sectionInfos[0].questionIds[0];
              let question = await QuestionModel.getOne(questionId);
              let questionBankId = question.questionBankId;
              window.open( `/teacher/examSingle?bankId=${questionBankId}&examId=${exam.id}`)
              break;
          }
        },
        // 获取某个考核学生答卷列表-包含未参加考核的
        async getOneExamUserResultList(exam) {
          let list = await QuestionExamModel.getOneExamAllResultList({
            examId: exam.id
          })
          $this.$set($this.exam.listD, "allList", list);
          for (let re of list) {
            $this.exam.listD.statusList[0].list.push(re) // 所有
            if (re.resultStatus == null) {// 未考核
              $this.exam.listD.statusList[1].list.push(re)
            }
            if (re.resultStatus === "UnSubmited") {// 已算分
              $this.exam.listD.statusList[2].list.push(re)
            }
            if (re.resultStatus === "CalculatedScore") {// 已算分
              $this.exam.listD.statusList[3].list.push(re)
            }
          }
        },
        // 导出学生列表-考核状态
        async exportList() {
          if ($this.exam.listD.allList.length > 0) {
            // map reduce生成arr
            function formatJson(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map(j => {
                let value = '';
                switch (j) {
                  case "resultStatus":
                    if (v['resultStatus'] == null) {// 未考核
                      value = "未答卷"
                    }
                    if (v['resultStatus'] === "UnSubmited") {// 已算分
                      value = "未交卷"
                    }
                    if (v['resultStatus'] === "CalculatedScore") {// 已算分
                      value = "已交卷"
                    }
                    break
                  case "resultStartTime":
                    if (v['resultStartTime'] === null) {
                      value = "/"
                    } else {
                      value = date_format(v['resultStartTime'], "yyyy-MM-dd HH:mm:ss")
                    }
                    break;
                  case "resultEndTime":
                    if (v['resultEndTime'] === null) {
                      value = "/"
                    } else {
                      value = date_format(v['resultEndTime'], "yyyy-MM-dd HH:mm:ss")
                    }
                    break;
                  case "resultScore":
                    if (v['resultScore'] === null) {
                      value = 0
                    } else {
                      value = v['resultScore']
                    }
                    break;
                  default:
                    value = v[j]
                }
                return value
              }))
            }

            const header = ['学生账号', '学生姓名', "答卷状态", "开始答卷时间", "交卷时间", "得分"];
            const filter = ["studentAccount", "studentName", "resultStatus", "resultStartTime", "resultEndTime", "resultScore"];
            // 导出excel
            excel_export_from_json($this.exam.listD.allList, header, filter, formatJson, $this.exam.clickedExam.name + "-学生考核状态列表-" + date_format(new Date(), "yyyy-MM-dd HH:mm:ss"))
          } else {
            msg_err('请先选择要导出的项目')
          }
        },
        // 点击查看答卷按钮
        clickViewResultBtn(resultId) {
          // 找到学生信息并传入参数
          let [, studentInfo] = find_obj_from_arr_by_id("resultId", resultId, $this.exam.listD.allList);
          window.open(`/teacher/examPaper?id=${resultId}&asType=teacherEdit&studentAccount=${studentInfo.studentAccount}&studentName=${studentInfo.studentName}`)
        },
      }
    },
  }
}
</script>

<style scoped>

</style>
